import React, { useRef, useEffect } from 'react';
import Select from 'react-select';

const CustomSelect = (props) => {
  const { selectRef, defaultValue } = props;
  console.log('selectRef:', selectRef)
  useEffect(() => {
    const handleMenuOpen = () => {
      if (selectRef && selectRef.current) {
        const menu = selectRef.current.getMenuListRef();
        console.log('menu:', menu)
        if (menu) {
          const selectedOption = menu.querySelector('.react-select__option--is-selected');
          console.log('selectedOption:', selectedOption)

          if (selectedOption) {
            selectedOption.scrollIntoView({ behavior: 'smooth', block: 'nearest' });
          }
        }
      }
    };

    console.log('check:', selectRef && selectRef.current)
    // Open the menu and scroll to the selected option
    if (selectRef && selectRef.current) {
      // selectRef.current.openMenu();
      handleMenuOpen();
    }
  }, [defaultValue]);

  return (
    <Select
      ref={selectRef}
      classNamePrefix={'VMSelect'}
      onMenuOpen={() => {
        setTimeout(() => {
          const selectedEl = document.getElementsByClassName("VMSelect__option--is-selected")[0];
          console.log('selectedEl:', selectedEl)
          if (selectedEl) {
            selectedEl.scrollIntoView({ behavior: 'smooth', block: 'start' });
          }
        }, 0);
      }}
      {...props}
    />
  );
};

export default CustomSelect;
