export const REDIRECT_URL = process.env.REACT_APP_REDIRECT_URL;
export const RecaptchaV2SiteKey = process.env.REACT_APP_RECAPTCHA_V2_SITE_KEY
export const PlayerUri = process.env.REACT_APP_PLAYER_URI;

export const DevEnv = process.env.REACT_APP_ENVIRONMENT;

export const Hosts = {
  API: process.env.REACT_APP_API_HOST,
  PLATFORM_SERVICE_API: process.env.REACT_APP_PLATFORM_API_HOST,
  METRICS_API: process.env.REACT_APP_METRICS_API,
  TVA_SERVICE_API: process.env.REACT_APP_TVA_API_HOST,
  EDGE_CLOUD_API: process.env.REACT_APP_EDGE_CLOUD_API_HOST,
  EDGE_CLOUD_CONTROLLER_API: process.env.REACT_APP_EDGE_CLOUD_CONTROLLER_HOST
}

export const TYPE = {
  API: 'API',
  PLATFORM_API: 'PLATFORM_API',
}

export const RequestState = {
  REQUEST: 'REQUEST',
  SUCCESS: 'SUCCESS',
  FAIL: 'FAIL',
  NOT_AUTHORIZED: 'NOT_AUTHORIZED',
}

export const Urls = {
  HOME: '/',
  LOGIN: '/login',
  SIGN_UP: '/sign-up',
  REQUEST_RESET_PASSWORD: '/request-reset-password',
  VERIFY_ACCOUNT: '/account/verify-email',
  PASSWORD_RESET: '/account/password-reset',
  ACCEPT_INVITE: '/account/accept-invite',
  CHANGE_EMAIL: '/account/update-email',

  ORGANIZATION_LIST: '/organizations',

  DASHBOARD: '/dashboard',
  DASHBOARD_DAPPS: '/dashboard/dapps',
  DASHBOARD_DAPPS_CREATE: '/dashboard/video/create',
  DASHBOARD_AI: '/dashboard/ai/:projectId',
  DASHBOARD_VIDEO: '/dashboard/video/videos',
  DASHBOARD_RENDERING: '/dashboard/rendering/:projectId',
  DASHBOARD_SUBSCRIBE: '/dashboard/subscribe',
  DASHBOARD_METRICS: '/dashboard/video/metrics',

  VIDEO_TAB: '/dashboard/video/:selectedTab',
  VIDEO_LIVESTREAMS: '/dashboard/video/livestreams',
  VIDEO_OVERVIEW: '/dashboard/video/overview',
  VIDEO_VIDEOS: '/dashboard/video/videos',
  VIDEO_WEBHOOKS: '/dashboard/video/webhooks',
  VIDEO_SETTINGS: '/dashboard/video/settings',
  VIDEO_CREATE_VIDEO: '/dashboard/video/videos/create',
  VIDEO_VIEW_VIDEO: '/dashboard/video/videos/:videoId',
  VIDEO_DELETE_VIDEO: '/dashboard/video/videos/:videoId/delete',
  VIDEO_CREATE_LIVESTREAM: '/dashboard/video/livestreams/create',
  VIDEO_VIEW_LIVESTREAM: '/dashboard/video/livestreams/:streamId',
  VIDEO_DELETE_LIVESTREAM: '/dashboard/video/livestreams/:streamId/delete',
  VIDEO_REGISTER_WEBHOOK: '/dashboard/video/webhooks/register',
  VIDEO_VIEW_WEBHOOK: '/dashboard/video/webhooks/registrations/:webhookRegId',
  VIDEO_DELETE_WEBHOOK: '/dashboard/video/webhooks/registrations/:webhookRegId/delete',
  VIDEO_WEB3THEATRE: '/dashboard/video/web3theatre',

  AI_TAB: '/dashboard/ai/:projectId/:selectedTab',
  AI_MODEL_DEPLOYMENT_DETAIL_TAB: '/dashboard/ai/:projectId/:selectedTab/:shard/:suffix',
  AI_JUPYTER_NOTEBOOK: '/dashboard/ai/:projectId/jupyter-notebook',
  AI_MODEL_EXPLORER: '/dashboard/ai/:projectId/model-explorer',
  AI_HYPER_PARAMETER_TUNING: '/dashboard/ai/:projectId/hyper-parameter-tuning',
  AI_NEURAL_ARCHITECTURE_SEARCH: '/dashboard/ai/:projectId/neural-architecture-search',
  AI_MODEL_FINE_TUNING: '/dashboard/ai/:projectId/model-fine-tuning',
  AI_MODEL_DEPLOYMENTS: '/dashboard/ai/:projectId/model-deployments',
  AI_MODEL_DEPLOYMENT_DETAIL: '/dashboard/ai/:projectId/model-deployment-detail/:shard/:suffix',
  AI_LLM_DETAIL: '/dashboard/ai/:projectId/llm-detail/:shard/:suffix',
  AI_NEW_DEPLOYMENT: '/dashboard/ai/:projectId/model-explorer',
  AI_RAY_CLUSTER: '/dashboard/ai/:projectId/ray-cluster',
  AI_RAW_MACHINES: '/dashboard/ai/:projectId/raw-machines',
  AI_GPU_MARKETPLACE: '/dashboard/ai/:projectId/gpu-marketplace',
  AI_THETA_EDGE_NODES: '/dashboard/ai/:projectId/theta-edge-nodes',
  AI_CLOUD_PARTNERS: '/dashboard/ai/:projectId/cloud-partners',
  AI_DATA_CENTER_PARTNERS: '/dashboard/ai/:projectId/data-center-partners',
  AI_DISTRIBUTED_NODE_PARTNERS: '/dashboard/ai/:projectId/distributed-node-partners',
  AI_GPU_NODE: '/dashboard/ai/:projectId/gpu-node',
  AI_GPU_NODE_DETAIL: '/dashboard/ai/:projectId/gpu-node-detail/:shard/:suffix',

  DASHBOARD_SETTINGS: '/dashboard/settings',
  SETTINGS_PROJECT: '/dashboard/settings/project/:projectId',
  SETTINGS_PROFILE: '/dashboard/settings/profile',
  SETTINGS_SECURITY: '/dashboard/settings/security',
  SETTINGS_TEAMS: '/dashboard/settings/teams',
  SETTINGS_PROJECTS: '/dashboard/settings/projects',
  SETTINGS_QUOTA: '/dashboard/settings/quota',
  SETTINGS_BILLING: '/dashboard/settings/billing',
  SETTINGS_SUPPORT: '/dashboard/settings/support',
  SETTINGS_USAGE: '/dashboard/settings/usage',

  MARKETING_TVA: '/tva',
  MARKETING_AI_SHOWCASES: '/ai-showcase',
  MARKETING_AI_SHOWCASES_EXPLORE: '/ai-showcase/explore',
  MARKETING_AI_SHOWCASE_TEXT_TO_IMAGE: '/ai-showcase/text-to-image',
  MARKETING_AI_SHOWCASE_CHATBOT: '/ai-showcase/chatbot',
  MARKETING_AI_SHOWCASE_IMAGE_TO_VIDEO: '/ai-showcase/image-to-video',
  MARKETING_AI_SHOWCASE_SKETCH_TO_3D: '/ai-showcase/sketch-to-3d',
  MARKETING_AI_SHOWCASE_VITON: '/ai-showcase/viton',
  MARKETING_AI_SHOWCASE_IMAGE_TO_IMAGE: '/ai-showcase/image-to-image',
  MARKETING_AI_SHOWCASE_IMAGE_TO_IMAGE_TABS: '/ai-showcase/image-to-image/:model',
  MARKETING_AI_SHOWCASE_ART_STYLE_TRANSFER: '/ai-showcase/image-to-image/art-style-transfer',
  MARKETING_AI_SHOWCASE_BACKGROUND_REMOVAL: '/ai-showcase/image-to-image/background-removal',
  MARKETING_AI_SHOWCASE_OBJECT_ERASING: '/ai-showcase/image-to-image/object-erasing',
  MARKETING_AI_SHOWCASE_IN_PAINTING: '/ai-showcase/image-to-image/in-painting',
  MARKETING_AI_SHOWCASE_IMAGE_UPSCALING: '/ai-showcase/image-to-image/image-upscaling',
  MARKETING_AI_CONTEST: '/contest',
  MARKETING_AI_SHOWCASE_TERMS: '/ai-showcase/terms',
  MARKETING_AI_SHOWCASE_PRIVACY_POLICY: '/ai-showcase/privacy-policy',
}

export const ShowcaseURls = [
  Urls.MARKETING_AI_SHOWCASES,
  Urls.MARKETING_AI_SHOWCASES_EXPLORE,
  Urls.MARKETING_AI_SHOWCASE_TEXT_TO_IMAGE,
  Urls.MARKETING_AI_SHOWCASE_CHATBOT,
  Urls.MARKETING_AI_SHOWCASE_IMAGE_TO_VIDEO,
  Urls.MARKETING_AI_SHOWCASE_IMAGE_TO_IMAGE_TABS,
  Urls.MARKETING_AI_SHOWCASE_SKETCH_TO_3D,
  Urls.MARKETING_AI_SHOWCASE_VITON,
  Urls.MARKETING_AI_CONTEST,
  Urls.MARKETING_AI_SHOWCASE_ART_STYLE_TRANSFER,
  Urls.MARKETING_AI_SHOWCASE_BACKGROUND_REMOVAL,
  Urls.MARKETING_AI_SHOWCASE_OBJECT_ERASING,
  Urls.MARKETING_AI_SHOWCASE_IN_PAINTING,
  Urls.MARKETING_AI_SHOWCASE_IMAGE_UPSCALING
]

export const SettingsUrls = [
  Urls.DASHBOARD_SETTINGS,
  Urls.SETTINGS_PROJECT,
  Urls.SETTINGS_PROFILE,
  Urls.SETTINGS_SECURITY,
  Urls.SETTINGS_TEAMS,
  Urls.SETTINGS_PROJECTS,
  Urls.SETTINGS_QUOTA,
  Urls.SETTINGS_BILLING,
  Urls.SETTINGS_SUPPORT,
  Urls.SETTINGS_USAGE
]

export const ModalTypes = {
  SELECT_PROJECT: 'SELECT_PROJECT',
  CREATE_PROJECT: 'CREATE_PROJECT',
  CREATE_NOTEBOOK: 'CREATE_NOTEBOOK',
  CREATE_DEPLOYMENT: 'CREATE_DEPLOYMENT',
  CREATE_CUSTOM_TEMPLATE: 'CREATE_CUSTOM_TEMPLATE',
  CREATE_ORG: 'CREATE_ORG',
  CREATE_API_KEY: 'CREATE_API_KEY',
  CREATE_GPU_NODE: 'CREATE_GPU_NODE',
  DELETE: 'DELETE',
  CONFIRM: 'CONFIRM',
  INVITE_USER: 'INVITE_USER',
  INVITE_TEAM_MEMBERS_TO_PROJECT: 'INVITE_TEAM_MEMBERS_TO_PROJECT',
  EDIT_PROFILE: 'EDIT_PROFILE',
  EDIT_PROJECT: 'EDIT_PROJECT',
  EDIT_ORG: 'EDIT_ORG',
  CHANGE_PHONE_NUMBER: 'CHANGE_PHONE_NUMBER',
  CHANGE_PASSWORD: 'CHANGE_PASSWORD',
  AUTO_RECHARGE: 'AUTO_RECHARGE',
  INCREASE_QUOTA: 'INCREASE_QUOTA',
  TWO_FA: 'TWO_FA',
  VERIFY_PHONE_NUMBER: 'VERIFY_PHONE_NUMBER',
  LOGIN: 'LOGIN',
  LOGOUT: 'LOGOUT',
  ADD_CREDIT: 'ADD_CREDIT',
  SIGNUP: 'SIGNUP',
  SHOWCASE_TEXT_TO_IMAGE_DISCOVER: 'SHOWCASE_TEXT_TO_IMAGE_DISCOVER',
};

export const SessionErrorCodes = {
  SESSION_NOT_FOUND: 1001,
  INVALID_SESSION_TYPE: 1002,
  INVALID_TOKEN: 1003,
  TOKEN_EXPIRED: 1004,
  ACCOUNT_DISABLED: 1005
}

export const NetworkNameToId = {
  'Theta Mainnet': 361,
  'Theta Testnet': 365,
  'Ethereum Mainnet': 1,
  'ETH Goerli Testnet': 5
}

export const NetworkIdToName = {
  361: 'Theta Mainnet',
  9065: 'POG',
  1: 'Ethereum Mainnet',
  365: 'Theta Testnet',
  5: 'ETH Goerli Testnet',
  137: 'Polygon',
  47683: 'GROVE',
  7734: 'Passaways'
}

export const SupportedNetworks = [
  { label: 'Theta Network', value: 361 },
  { label: 'POGCHAIN', value: 9065 },
  { label: 'GROVE', value: 47683 },
  { label: 'Passaways', value: 7734 },
  { label: 'Ethereum Network', value: 1 },
  { label: 'Polygon Network', value: 137 },
  { label: 'Theta Testnet', value: 365 },
  { label: 'ETH Goerli Testnet', value: 5 },
]

//TODO: read env
export const StripePublicKey = process.env.STRIPE_PUBLIC_KEY;

export const LeftBarNames = {
  'views': 'Views',
  'unique_viewers': 'Unique Viewers',
  'playing_time': 'Playing Time',
  'viewer_experience_score': 'Overall Viewer Experience',
  'playback_success_score': 'Playback Success Score',
  'playback_failure_percentage': 'Playback Failure Percentage',
  'exits_before_video_start': 'Exits Before Video Start',
  'startup_time_score': 'Startup Time Score',
  'video_startup_time': 'Video Startup Time',
  'player_startup_time': 'Player Startup Time',
  'smoothness_score': 'Smoothness Score',
  'rebuffer_count': 'Rebuffer Count',
  'video_quality_score': 'Video Quality Score',
  'upscale_percentage': 'Upscale Percentage',
  'akamai_hits': 'URL Hits',
  'akamai_volume': 'URL Volume',
  'url_viewers': 'URL Viewers',
}

export const BreakDownCategory = ['device', 'player', 'video_metadata', 'stream', 'geography', 'view'];

export const BreakDownList = {
  'device': ['browser', 'browser_version'],
  'player': ['player_name', 'player_version'],
  'video_metadata': ['video_title', 'video_id'],
  'stream': ['video_stream_type', 'source_type'],
  'geography': ['continent', 'country'],
  'view': ['view_id', 'error_code']
}


export const UserRoleIds = {
  ADMIN: 'admin',
  EDITOR: 'editor',
  VIEWER: 'viewer'
}

export const UserRoleLabels = {
  [UserRoleIds.ADMIN]: 'Admin',
  [UserRoleIds.EDITOR]: 'Editor',
  [UserRoleIds.VIEWER]: 'Member'
}

export const TopUpList = [10, 20, 50, 100, 200, 500, 1000, 2000];