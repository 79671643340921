import React, { useEffect, useState, useRef } from 'react';
import { useDispatch, useSelector } from "react-redux";
import { EllipsisIcon, CopyIcon } from "../../../components/Svg";
import { ModalTypes, Urls } from '../../../constants';
import UIState from '../../../store/UIState';
import Ai, { selectAllGpuNodes, selectAllVMs } from '../../../store/models/Ai';
import { getLinkByDeployment, checkActiveSSH, getVmResourcesString } from '../../../utils';
import { getStatusSum, checkActivePod, age, checkDeploymentsReady } from '../../../utils';
import classNames from 'classnames';
import { Button } from '../../../components/Button';
import Loader from '../../../components/Loader';
import { selectCurrentUserId } from '../../../store/models/User';
import { selectCurrentProjectId } from '../../../store/models/Project';
import _ from "lodash";
import { FaCheckCircle, FaEdit, FaTimesCircle } from "react-icons/fa";
import { selectAppStarted } from '../../../store/AppState';
import ApiKeyNote from '../../../components/ApiKeyNote';
import Tooltip from "../../../components/Tooltip";
import { mergeUrl } from "../../../utils/url";
import { useHistory } from 'react-router-dom';
import { toast } from "react-toastify";


function GPUNodePage() {
  const dispatch = useDispatch();
  const [loading, setLoading] = useState(false);
  const appStarted = useSelector(state => selectAppStarted(state));
  const userId = useSelector(state => selectCurrentUserId(state));
  const projectId = useSelector(state => selectCurrentProjectId(state));
  const gpuNodes = useSelector(state => selectAllGpuNodes(state, projectId));
  const vms = useSelector(state => selectAllVMs(state));
  const hasNoGpuNodes = (!_.isNil(gpuNodes) && _.isEmpty(gpuNodes));
  const [activePopup, setActivePopup] = useState(-1);
  const history = useHistory();
  let timeoutId;

  useEffect(() => {
    if (!appStarted || _.isNil(userId)) return;
    setLoading(true);
    async function fetchData() {
      const gpuNodes = await dispatch(Ai.actions.fetchGpuNodes(projectId));
      setLoading(false);
      if (!checkDeploymentsReady(gpuNodes)) {
        timeoutId = setTimeout(fetchData, 1000 * 5)
      }
    }
    fetchData();
    return () => { clearTimeout(timeoutId) };
  }, [projectId, Object.keys(gpuNodes).length]);


  const handleOpenModel = () => {
    dispatch(UIState.actions.showModal(ModalTypes.CREATE_GPU_NODE))
  }

  const handlePopup = (e, i) => {
    e.stopPropagation();
    e.preventDefault();
    if (i === -1) {
      setActivePopup(i)
      return;
    }
    setActivePopup(v => {
      if (v === i) return -1;
      return i;
    })
  }

  const handleDelete = (gpuNode) => {
    const onConfirm = async () => {
      await dispatch(Ai.actions.deleteDeployment(projectId, gpuNode.Shard, gpuNode.Suffix));
      await dispatch(Ai.actions.fetchGpuNodes(projectId));
    }
    dispatch(UIState.actions.showModal(ModalTypes.DELETE, { title: gpuNode.Name, onConfirm }));
    setActivePopup(-1);
  }

  const handleShow = (gpuNode) => {
    let portKey = _.get(gpuNode, 'AdditionalPorts.0');
    let mapPort = _.get(gpuNode, `PortMappings.${portKey}`);
    const msgString = `ssh -i ~/.ssh/id_rsa -p ${mapPort} root@${_.get(gpuNode, 'NodePublicIP')}`
    const message = (
      <div style={{ display: 'flex' }}>
        {msgString} <div className='AiServicePage__copy-button-wrap'
          onClick={() => {
            console.log('onClick')
            navigator.clipboard.writeText(msgString);
            toast.success("Copied to clipboard")
          }} >
          <CopyIcon />
        </div>
      </div>
    );
    dispatch(UIState.actions.showModal(ModalTypes.CONFIRM, {
      title: "SSH to the node",
      message: message
    }));
  }

  const navToDetail = (suffix, shard) => {
    history.push(mergeUrl(Urls.AI_GPU_NODE_DETAIL, { projectId, suffix, shard }));
  }

  return (<div className={'AiServicePage'}>
    <div className={'AiServicePage__header'}>
      <div className={'AiServicePage__header--title'}>
        GPU Node
        <ApiKeyNote type={'GPU nodes'} />
      </div>
      <div className={'AiServicePage__header--button'} onClick={handleOpenModel}>
        <div className={'AiServiceHeaderButtonIcon empty'}>
        </div>
        New GPU Node
      </div>
    </div>
    <div className={'AiServicePage__content'}>
      {loading &&
        <div className={'EmptyState'}>
          <Loader size={'large'} color={'green'} />
        </div>}
      {!hasNoGpuNodes && !loading && <div className={'AiServicePage__table'}>
        <div className={'AiServicePage__table--header'}>
          <div className={'AiServicePage__table--header-row'}>
            <div className={'AiServicePage__table--cell title'}>Name</div>
            <div className={'AiServicePage__table--cell title'}>ID</div>
            <div className={'AiServicePage__table--cell view'}>SSH</div>
            <div className={'AiServicePage__table--cell view'}>HTTP Endpoint</div>
            <div className={'AiServicePage__table--cell status'}>Status</div>
            <div className={'AiServicePage__table--cell duration'}>Duration</div>
            <div className={'AiServicePage__table--cell status'}>Machine Type</div>
            <div className={'AiServicePage__table--cell ellipsis'}></div>
          </div>
        </div>
        <div className={'AiServicePage__table--body'}>
          {Object.keys(gpuNodes).map((k, i) => {
            const gpuNode = gpuNodes[k];
            const httpPort = _.get(gpuNode, 'ContainerPort');
            return (
              <div className={'AiServicePage__table--row clickable'} key={i} onClick={() => navToDetail(gpuNode.Suffix, gpuNode.Shard)}>
                <div className={'AiServicePage__table--cell title'}>
                  <NameSection deployment={gpuNode} />
                </div>
                <div className={'AiServicePage__table--cell title'}>
                  {gpuNode.Name}
                </div>
                <div className={'AiServicePage__table--cell view'}>
                  <div className={classNames('AiServicePageViewButton', { active: checkActiveSSH(gpuNode) })}
                    onClick={(e) => {
                      e.stopPropagation();
                      handleShow(gpuNode)
                    }}>
                    Show
                  </div>
                </div>
                <div className={classNames('AiServicePage__table--cell view')}>
                  <div className={classNames('AiServicePageViewButton', { active: checkActivePod(gpuNode) && httpPort > 0 })}>
                    <a href={checkActivePod(gpuNode) && httpPort > 0 ? getLinkByDeployment(gpuNode) : ''} target='_blank' onClick={(e) => e.stopPropagation()} >
                      Open
                    </a>
                  </div>
                </div>
                <div className={'AiServicePage__table--cell status icon'}>{getStatusSum(gpuNode.PodPhase, false)}</div>
                <div className={'AiServicePage__table--cell duration'}>{age(gpuNode.CreatedAt)}</div>
                <div className={'AiServicePage__table--cell status'}>
                  <Tooltip tooltip={<div className={"AiServicePage__tooltip"}>
                    {getVmResourcesString(vms[gpuNode.MachineType].resources)}
                  </div>}>{vms[gpuNode.MachineType].name}</Tooltip>
                </div>
                <div className={'AiServicePage__table--cell ellipsis'}>
                  <div className={'AiServicePageEllipsis'} onClick={(e) => handlePopup(e, i)}>
                    <EllipsisIcon />
                  </div>
                  {activePopup === i && <div className={'AiServicePageEllipsis__popup'} onClick={(e) => { e.stopPropagation() }}>
                    <div className={'AiServicePageEllipsis__popup--row'} onClick={() => handleDelete(gpuNode)}>Delete</div>
                  </div>}
                </div>
              </div>
            )
          })}
        </div>
      </div>}
      {hasNoGpuNodes && !loading &&
        <div className={'EmptyState'}>
          <div className={'EmptyState__title'}>No GPU Node</div>
          <div className={'EmptyState__subtitle'}>Create your first GPU Node with SSH access to get started</div>
          <Button size={'large'}
            title={'New GPU Node'}
            onClick={handleOpenModel}
          />
        </div>}
    </div>
  </div>
  );
}

const NameSection = ({ deployment }) => {
  const dispatch = useDispatch();
  const [isEditing, setIsEditing] = useState(false);
  const nameRef = useRef();
  const handleEdit = () => {
    setIsEditing(true);
  }
  const handleSubmit = async () => {
    let nickname = nameRef.current.value;
    let projectId = deployment.ProjectID;
    await dispatch(Ai.actions.updateDeployment(deployment.Shard, deployment.Suffix, {
      project_id: projectId,
      annotations: {
        nickname: nickname,
        tags: deployment.Annotations.tags
      }
    }))
    await dispatch(Ai.actions.fetchGpuNodes(projectId));
    setIsEditing(false);
  }
  const handleCancel = () => {
    setIsEditing(false);
  }
  const handleKeyDown = (e) => {
    if (e.key === 'Enter') {
      handleSubmit();
    }
  }

  return <div onClick={e => e.stopPropagation()} className='AiServicePage__name-warp'>
    {!isEditing ? deployment.Annotations.nickname || `\u00A0` :
      <input type='text' ref={nameRef} defaultValue={deployment.Annotations.nickname} onKeyDown={handleKeyDown} />}
    {isEditing ?
      <div className='AiServicePage__icon-warp'>
        <div className='PageTitleBar__right--icon cancel' onClick={handleCancel} title="Cancel"><FaTimesCircle /></div>
        <div className='PageTitleBar__right--icon submit' onClick={handleSubmit} title="Submit"><FaCheckCircle /></div>
      </div> :
      <div className='AiServicePage__icon-warp'>
        <div className='PageTitleBar__right--icon edit' onClick={handleEdit}><FaEdit /></div>
      </div>}
  </div>
}

export default GPUNodePage;