import React, { useState, useRef, useEffect } from 'react';
import cx from 'classnames';
import { useSelector, useDispatch } from "react-redux";
import Modal, { ModalActions, ModalContent, ModalHeader, ModalTitle, ModalSubtitle } from "../components/Modal";
import { Button } from "../components/Button";
import Select from 'react-select'
import classNames from 'classnames';
import Ai, { selectAllVMs, selectAllstandardTemplates, selectAllCustomTemplates, selectAllDeployments } from '../store/models/Ai';
import { generateRandomId, getVMOptions, formatPrice, getErrorMsg } from '../utils';
import { selectCurrentProjectId } from '../store/models/Project';
import { useHistory } from 'react-router-dom';
import { ModalTypes } from '../constants';
import { selectCurrentUser, selectCurrentUserId } from '../store/models/User';
import UIState from '../store/UIState';
import { toast } from "react-toastify";
import { selectCurrentOrgId } from '../store/models/Organization';
import Loader from "../components/Loader";
import { QuestionMarkIcon } from '../components/Svg';
import Tooltip from '../components/Tooltip';
import CustomSelect from '../components/CustomSelect';

const selectStyles = {
  container: styles => ({
    ...styles,
    flex: 1,
    minWidth: 390,
    height: 40,
  }),
  control: (styles, { isFocused }) => ({
    ...styles,
    paddingLeft: 15,
    backgroundColor: '#191D29',
    borderColor: '#3D4463',
    borderRadius: 6,
    boxShadow: isFocused ? '0 0 0 1px #18c99d' : 'none',
    color: '#18c99d',
    ':hover': {
      borderColor: '#18c99d',
      boxShadow: '0 0 0 1px #18c99d',
    },
    '.selected': {
      borderColor: '#18c99d',
      boxShadow: '0 0 0 1px #18c99d',
    }
  }),
  menu: (styles, state) => ({
    ...styles,
    border: '1px solid #3D4463',
    backgroundColor: '#191D29',
  }),
  option: (styles, { data, isDisabled, isFocused, isSelected }) => {
    return {
      ...styles,
      backgroundColor: '#191D29',
      borderColor: '#3D4463',
      color: isDisabled ? '#636B91' : (isFocused ? '#18c99d' : (isSelected ? '#18c99d' : '#636B91')),
      opacity: isDisabled ? 0.5 : 1,
    };
  },
  indicatorsContainer: (styles, { isDisabled }) => ({
    ...styles,
    display: isDisabled ? 'none' : 'flex',
  }),
  dropdownIndicator: (styles, state) => ({
    ...styles,
    color: '#8A8FB5',
  }),
  indicatorSeparator: (styles, state) => ({
    ...styles,
    color: '#636B91',
    backgroundColor: '#636B91',
    display: 'none'
  }),
  input: styles => ({ ...styles }),
  placeholder: styles => ({ ...styles, color: '#636B91' }),
  singleValue: (styles, { data }) => ({ ...styles, color: '#636B91', fontWeight: '500' }),
};

export const CreateDeploymentModal = ({ modalType, onRequestClose, modalId }) => {
  const [loading, setLoading] = useState(false);
  const [error, setError] = useState(null);
  const dispatch = useDispatch()
  const history = useHistory();
  const userId = useSelector(state => selectCurrentUserId(state));
  const projectId = useSelector(state => selectCurrentProjectId(state));
  const orgId = useSelector(state => selectCurrentOrgId(state));
  const standardTemplates = useSelector(state => selectAllstandardTemplates(state));
  const deployments = useSelector(state => selectAllDeployments(state, projectId));
  let isCustom = false;
  let curTemplate = standardTemplates.filter(img => img.id === modalId)[0];
  if (!curTemplate) {
    const customTemplate = useSelector(state => selectAllCustomTemplates(state, projectId))
    curTemplate = customTemplate.filter(img => img.id === modalId)[0];
    isCustom = true;
  }
  // console.log("curTemplate:", curTemplate)
  // console.log("deployments:", deployments)
  const currentUser = useSelector(state => selectCurrentUser(state));
  const imagesOptions = curTemplate && curTemplate?.container_images ? curTemplate.container_images.map(img => ({ label: img, value: img })) : [];
  const VMs = useSelector(state => selectAllVMs(state));
  let suggestedVM = _.get(curTemplate, 'suggested_vms.0')
  let vmOptions = getVMOptions(VMs, suggestedVM);

  if (!vmOptions || !curTemplate) {
    return <Loader />
  }

  let defaultVM = vmOptions.filter(o => o.value === suggestedVM)[0] || vmOptions?.[0];
  // const [selectedFramework, setSelectedFramework] = useState('gradio');
  const pUnit = _.get(VMs, `${suggestedVM || vmOptions?.[0]?.value}.price_hour`) / 100;
  let [price, setPrice] = useState({ unit: pUnit, amount: 1 })
  const defaultName = curTemplate.name.toLowerCase().replace(/[^\w\s]|_/g, '').replace(/\s/g, '').slice(0, 10) + generateRandomId(10);

  const nameRef = useRef();
  const dnameRef = useRef();
  const imageRef = useRef();
  const portRef = useRef();
  const vmRef = useRef();
  const replicaRef = useRef();
  const varsRef = useRef();
  const authUserRef = useRef();
  const authPwdRef = useRef();

  const [enableAuth, setEnableAuth] = useState(false);

  const onSubmit = async () => {
    if (_.isNil(userId)) {
      dispatch(UIState.actions.showModal(ModalTypes.SIGNUP))
      return;
    }
    let name = nameRef.current.value;
    if (name.length) {
      if (!/^[A-Za-z0-9]+$/.test(name)) {
        const errorStr = 'Invalid ID. The ID should only contain letters and numbers.'
        setError(errorStr);
        toast.error('Error: ' + errorStr);
        return;
      }
      if (name.length > 20) {
        const errorStr = 'Invalid ID. The length of the ID should be less than or equal to 20.'
        setError(errorStr);
        toast.error('Error: ' + errorStr)
        return;
      }
    }
    let port = portRef.current.value;
    if (!port) {
      const errorStr = 'Container port is required.'
      setError(errorStr);
      toast.error('Error: ' + errorStr)
      return;
    }
    let image = imageRef.current.state.selectValue[0]?.value;
    if (isCustom) {
      image = image[0];
    }
    if (!image) {
      const errorStr = 'Image is required.'
      setError(errorStr);
      toast.error('Error: ' + errorStr)
      return;
    }
    let vm = vmRef.current.state.selectValue[0]?.value;
    if (!vm) {
      setError('Machine type is required.');
      return;
    }
    let tags = curTemplate.tags;
    let dname = dnameRef.current.value;
    let authUser = enableAuth ? authUserRef.current.value : "";
    let authPwd = enableAuth ? authPwdRef.current.value : "";
    // console.log('name:', name, ' image:', image)
    // console.log('port:', port, ' vm:', vm)
    // console.log('projectId:', projectId)
    const imageId = curTemplate.id;
    const replica = Number(replicaRef.current.value)
    const newDeployment = {
      name: name,
      project_id: projectId,
      deployment_image_id: imageId,
      container_image: image,
      min_replicas: replica,
      max_replicas: replica,
      vm_id: vm,
      annotations: {
        tags: JSON.stringify(tags),
        nickname: dname
      },
      auth_username: authUser,
      auth_password: authPwd
    }
    if (curTemplate.require_env_vars) {
      let envVars;
      try {
        if (varsRef.current.value.trim() !== '') {
          envVars = JSON.parse(varsRef.current.value);
        } else {
          envVars = null;
        }
      } catch (error) {
        envVars = 'invalid JSON';
      }
      if (envVars == null) {
        setError('Environment varible is requeired.');
        varsRef.current.focus();
        return;
      }
      if (envVars == 'invalid JSON') {
        setError('Environment varible is requeired.');
        varsRef.current.focus();
        return;
      }
      if (!envVars.HUGGING_FACE_HUB_TOKEN) {
        setError('Environment varible must contain HUGGING_FACE_HUB_TOKEN field.');
        varsRef.current.focus();
        return;
      }
      if (envVars.HUGGING_FACE_HUB_TOKEN === 'hf_xxx') {
        setError(<>Please specify your HuggingFace user access token. Click <a target='_blank' href='https://huggingface.co/docs/hub/en/security-tokens'>HERE</a> to learn how to obtain the token.</>);
        varsRef.current.focus();
        return;
      }
      newDeployment.env_vars = envVars;
    }
    console.log('obj:', newDeployment);
    // return;
    setLoading(true);
    setError(null);
    try {
      const deployment = await dispatch(Ai.actions.createDeployment(newDeployment))
      console.log('deployment:', deployment);
      await dispatch(Ai.actions.fetchDeployments(projectId));
      onRequestClose();
      history.push(`/dashboard/ai/${projectId}/model-deployments`)
    } catch (e) {
      setLoading(false);
      let error = getErrorMsg(e.message, onRequestClose, currentUser, orgId, vm);
      setError(error);
      if (error === e.message) {
        toast.error(e.message)
      }
    }
  }

  const onVMChange = (v) => {
    setPrice({ unit: VMs[v.value].price_hour / 100, amount: price.amount });
  }

  return (
    <Modal className={cx("CreateDeploymentModal")}
      onRequestClose={onRequestClose}>

      <ModalHeader>
        <ModalTitle align={'row'}>
          Create New Deployment
          <Tooltip tooltip={'Open documentation'}>
            <a href="https://docs.thetatoken.org/docs/serving-generative-ai-models" target='_blank'>
              <QuestionMarkIcon />
            </a>
          </Tooltip>
        </ModalTitle>
        <ModalSubtitle>{curTemplate.name}</ModalSubtitle>
      </ModalHeader>

      <ModalContent>
        <div className='CreateDeploymentModal__row'>
          <div className='CreateDeploymentModal__section-wrap name'>
            <div className='CreateDeploymentModal__select-label'>Deployment ID</div>
            <input className={"CreateDeploymentModal__input"}
              placeholder={'Enter new deployment ID'}
              ref={nameRef}
              defaultValue={defaultName}
              disabled={true}
            />
          </div>
          <div className='CreateNotebookModal__select-wrap name'>
            <div className='CreateNotebookModal__select-label'>Deployment Name&nbsp;<div className='text-optional'>(optional)</div></div>
            <input className={"CreateNotebookModal__input"}
              placeholder={'Enter new deployment name'}
              ref={dnameRef}
            />
          </div>
        </div>
        <div className='CreateDeploymentModal__row'>
          <div className='CreateDeploymentModal__section-wrap image'>
            <div className='CreateDeploymentModal__select-label'>Image</div>
            <Select options={imagesOptions}
              className={"CreateDeploymentModal__selector"}
              defaultValue={imagesOptions[0]}
              styles={selectStyles}
              placeholder={'Select Template Image'}
              isDisabled={imagesOptions.length < 2}
              ref={imageRef}
            />
          </div>
          <div className='CreateDeploymentModal__section-wrap port'>
            <div className='CreateDeploymentModal__select-label'>Container Port</div>
            <input className={"CreateDeploymentModal__input"}
              placeholder={'Enter new deployment container port'}
              defaultValue={curTemplate ? curTemplate.container_port : 3000}
              ref={portRef}
            />
          </div>
        </div>
        <div className='CreateDeploymentModal__row'>
          <div className='CreateDeploymentModal__select-wrap auth'>
            <div className='CreateDeploymentModal__select-label'>Authentication&nbsp;<div className='text-optional'>(optional)</div></div>
            <div className='CreateDeploymentModal__select-content'>
              <label className='CreateDeploymentModal__switch'>
                <input className='CreateDeploymentModal__switch-input' type="checkbox" value={enableAuth} onClick={() => setEnableAuth(v => !v)} />
                <span className="CreateDeploymentModal__switch-slider"></span>
              </label>
              <input className={"CreateDeploymentModal__input auth"}
                placeholder={enableAuth ? 'Enter authentication username' : 'Authentication disabled'}
                disabled={!enableAuth}
                ref={authUserRef}
                type='text'
              />
              <input className={"CreateDeploymentModal__input auth"}
                placeholder={enableAuth ? 'Enter authentication password' : 'Authentication disabled'}
                disabled={!enableAuth}
                ref={authPwdRef}
                type='password'
              />
            </div>
          </div>
        </div>
        <div className='CreateDeploymentModal__row'>
          <div className='CreateDeploymentModal__section-wrap vms'>
            <div className='CreateDeploymentModal__select-label'>Machine type</div>
            <CustomSelect options={vmOptions}
              className={"CreateDeploymentModal__selector"}
              defaultValue={defaultVM}
              styles={selectStyles}
              placeholder={'Select Machine type'}
              isDisabled={vmOptions.length < 2}
              selectRef={vmRef}
              onChange={onVMChange}
              isOptionDisabled={(option) => {
                if (!suggestedVM) return false;
                return option.price < Number(VMs[suggestedVM].price_hour)
              }} />
          </div>
        </div>
        {curTemplate.require_env_vars && <div className='CreateDeploymentModal__row'>
          <div className='CreateDeploymentModal__section-wrap args'>
            <div className='CreateDeploymentModal__select-label'>Container Environment Variables</div>
            <input className={"CreateDeploymentModal__input"}
              placeholder={'Enter model environment variables. Ex: {"key1": "value1", "key2": "value2", ...}'}
              defaultValue={curTemplate.env_vars ? JSON.stringify(curTemplate.env_vars) : ''}
              ref={varsRef}
            />
          </div>
        </div>}
        <div className='CreateDeploymentModal__row'>
          <div className='CreateDeploymentModal__section-wrap name'>
            <div className='CreateDeploymentModal__select-label'>Replicas</div>
            <div className='CreateDeploymentModal__row-wrap'>
              <NumberInputSection className='CreateDeploymentModal__number-input-section' defaultValue={1} vRef={replicaRef} setPrice={setPrice} />
            </div>
          </div>
        </div>
        {/* <div className='CreateDeploymentModal__row'>
          <div className='CreateDeploymentModal__section-wrap name'>
            <div className='CreateDeploymentModal__select-label'>Replicas Range</div>
            <div className='CreateDeploymentModal__row-wrap'>
              <NumberInputSection className='CreateDeploymentModal__number-input-section' label='Minimum' vRef={minReplicaRef} />
              <NumberInputSection className='CreateDeploymentModal__number-input-section' label='Maximum' defaultValue={1} vRef={maxReplicaRef} />
            </div>
          </div>
        </div> */}
        {/* <div className='CreateDeploymentModal__row'>
          <div className='CreateDeploymentModal__section-wrap cpu'>
            <div className='CreateDeploymentModal__select-label'>CPU</div>
            <Select options={NotebookImages}
              className={"CreateDeploymentModal__selector"}
              value={{}}
              styles={selectStyles}
              placeholder={'Select CPU'}
            />
          </div>
          <div className='CreateDeploymentModal__section-wrap ram'>
            <div className='CreateDeploymentModal__select-label'>RAM</div>
            <Select options={NotebookImages}
              className={"CreateDeploymentModal__selector"}
              value={{}}
              styles={selectStyles}
              placeholder={'Select RAM'}
            />
          </div>
          <div className='CreateDeploymentModal__section-wrap disk-size'>
            <div className='CreateDeploymentModal__select-label'>Disk Size</div>
            <Select options={NotebookImages}
              className={"CreateDeploymentModal__selector"}
              value={{}}
              styles={selectStyles}
              placeholder={'Select Disk Size'}
            />
          </div>
          <div className='CreateDeploymentModal__section-wrap gpu-model'>
            <div className='CreateDeploymentModal__select-label'>GPU Model</div>
            <Select options={NotebookGPUModels}
              className={"CreateDeploymentModal__selector"}
              value={{
                label: 'NVidia A100',
                value: 'NVidia A100'
              }}
              styles={selectStyles}
              placeholder={'Select GPU Model'}
            />
          </div>
          <div className='CreateDeploymentModal__section-wrap gpu-count'>
            <div className='CreateDeploymentModal__select-label'>GPU Count</div>
            <Select options={NotebookGPUCounts}
              className={"CreateDeploymentModal__selector"}
              value={{
                label: '4',
                value: '4'
              }}
              styles={selectStyles}
              placeholder={'Select GPU Count'}
            />
          </div>
        </div> */}
        {/* <div className='CreateDeploymentModal__row'>
          <div className='CreateDeploymentModal__section-wrap name'>
            <div className='CreateDeploymentModal__select-label'>Framework</div>
            <div className='CreateDeploymentModal__row-wrap'>
              <div className='CreateDeploymentModal__select-group' onClick={() => setSelectedFramework('gradio')}>
                <div className='CreateDeploymentModal__select-group--row'>
                  <div className={classNames('CreateDeploymentModal__select-group--icon')}>
                    <div className={classNames('CreateDeploymentModal__select-group--circle', { 'active': selectedFramework === 'gradio' })}></div>
                  </div>
                  <div className='CreateDeploymentModal__select-group--text title'>Gradio</div>
                </div>
                <div className='CreateDeploymentModal__select-group--row'>
                  <div className='CreateDeploymentModal__select-group--icon'></div>
                  <div className='CreateDeploymentModal__select-group--text'>Prototype / Demo</div>
                </div>
              </div>
              <div className='CreateDeploymentModal__select-group' onClick={() => setSelectedFramework('mosec')}>
                <div className='CreateDeploymentModal__select-group--row'>
                  <div className={classNames('CreateDeploymentModal__select-group--icon')}>
                    <div className={classNames('CreateDeploymentModal__select-group--circle', { 'active': selectedFramework === 'mosec' })}></div>
                  </div>
                  <div className='CreateDeploymentModal__select-group--text title'>Mosec</div>
                </div>
                <div className='CreateDeploymentModal__select-group--row'>
                  <div className='CreateDeploymentModal__select-group--icon'></div>
                  <div className='CreateDeploymentModal__select-group--text'>Online Inference</div>
                </div>
              </div>
            </div>
          </div>
        </div> */}
        {!isNaN(price.unit) && <div className='CreateDeploymentModal__price-info'>
          <div className='CreateDeploymentModal__price-info--note'>
            Cost Estimation
          </div>
          <div className='CreateDeploymentModal__price-info--price'>
            ~ ${formatPrice(price.unit * price.amount)} per hour.
          </div>
        </div>}
        {error && <div className={cx("CreateDeploymentModal__error")}>{error}</div>}
      </ModalContent>

      <ModalActions>
        <Button color={"green"} onClick={onSubmit} title={"Create New Deployment"} loading={loading} />
        <Button color={"transparent"} onClick={onRequestClose} title={"Cancel"} />
      </ModalActions>

    </Modal>
  );
}


function NumberInputSection(props) {
  const { className, label, defaultValue = 0, vRef, setPrice } = props;
  const [value, setValue] = useState(defaultValue);
  const [isInputFocused, setInputFocused] = useState(false);
  const [isButtonClicked, setButtonClicked] = useState(false);
  const parentRef = useRef(null);

  useEffect(() => {
    const handleClickOutside = (event) => {
      if (parentRef.current && !parentRef.current.contains(event.target)) {
        setInputFocused(false);
        setButtonClicked(false);
      }
    };

    document.addEventListener('mousedown', handleClickOutside);
    return () => {
      document.removeEventListener('mousedown', handleClickOutside);
    };
  }, []);


  const decreaseValue = () => {
    if (value > 1) {
      setValue(value - 1);
      setPrice(p => ({ unit: p.unit, amount: value - 1 }))
    }
    setButtonClicked(true);
  };

  const increaseValue = () => {
    setValue(value + 1);
    setPrice(p => ({ unit: p.unit, amount: value + 1 }))
    setButtonClicked(true);
  };

  const handleOnChange = (e) => {
    let v = Math.max(Number(e.target.value), 1);
    setValue(v);
    setPrice(p => ({ unit: p.unit, amount: v }))
  }

  const handleFocus = () => {
    setInputFocused(true);
  };

  const handleBlur = () => {
    setInputFocused(false);
  };


  return (
    <div className={classNames("number-input", className, { 'focus': isInputFocused || isButtonClicked })} ref={parentRef}>
      {label && <label htmlFor="number-input" className='number-input__label'>{label}</label>}
      <div className={classNames("number-input__group", { 'no-label': !label })}>
        <div className="number-input__button" onMouseDown={decreaseValue}>-</div>
        <input
          type="number"
          id="number-input"
          className='number-input__input'
          inputMode="numeric"
          value={value}
          onChange={handleOnChange}
          ref={vRef}
          min={1}
          onFocus={handleFocus}
          onBlur={handleBlur}
        />
        <div className="number-input__button" onMouseDown={increaseValue}>+</div>
      </div>
    </div>
  );
}

export default NumberInputSection;
